import React from "react"
import LayoutCourse from "../../../../layouts/course"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"
import Box from "../../../../components/box"
import Bullet from "../../../../components/bullet"
import BulletList from "../../../../components/bullet-list"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import Heading from "../../../../components/heading"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "zusammenfassung",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum nächsten Kapitel"
          to="/kurse/hoelderlins-reisen/02-reiseerinnerung"
        />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Zusammenfassung
          </Heading>
          <Paragraph>
            Nun hast du Hölderlin schon ein wenig kennengelernt:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Du hast dir ein Bild von ihm als Schüler angesehen und es
              beschrieben. Seine Freunde sagten, er sah sehr hübsch, sanft und
              gepflegt aus.
            </Bullet>
            <Bullet>
              Du hast in seinem Tagebuch gelesen und seine Reiseroute verfolgt.
            </Bullet>
            <Bullet>Du hast von seiner Liebe zum Wasser gehört.</Bullet>
            <Bullet>
              Und du hast errechnet, wieviel Geld er auf der Reise ausgegeben
              hat.
            </Bullet>
            <Bullet>
              Du hast festgestellt, dass Hölderlin in seinen Reisenotizen eine
              ganze Menge Rechtschreibfehler gemacht hat.
            </Bullet>
            <Bullet>
              Und du hast seine Reiseerlebnisse mit Emojis kommentiert.
            </Bullet>
            <Bullet>
              Zum Schluss hast du dir eine Fortsetzungsgeschichte zu Hölderlins
              Reise ausgedacht.
            </Bullet>
            <Bullet>
              Du hast dich in Hölderlins Rolle hineinversetzt und im Brief an
              die Mutter davon berichtet.
            </Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
